import styled from 'styled-components';

export const BodyContainer = styled.div`
  background: #fff;
  color: #6e2b8b;
  font-family: 'Figtree-Regular', arial, sans-serif;
  font-size: 15px;
  line-height: 130%;
  letter-spacing: 0.02em;
`;

export const BodyStyle = styled.div`
  width: 860px;
  margin: 0 auto;
  padding: 0;
  text-align: center;
  @media (max-width: 991px) {
    width: 100%;
    margin: 0;
    height: auto;
    padding-top: 10px;
  }
`;

export const BodyContent = styled.div`
  width: 650px;
  margin: 0 auto;
  @media (max-width: 991px) {
    width: 100%;
    padding: 20px 35px 0 35px;
  }
`;

export const ImageStyle = styled.img`
  width: 250px;
  height: auto;
  display: inline-block;
  margin-left: 40px;
  @media (max-width: 991px) {
    display: block;
    width: 70%;
    height: auto;
    margin: 0 auto;
  }
`;

export const TextDiv = styled.p`
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 22px;
  color: #666;
  display: inline-block;
  width: 300px;
  margin-top: 25px;
`;

export const HeaderH1 = styled.h1`
  font-size: 25px;
  color: #6e2b8b;
`;

export const ParaText = styled.p``;
