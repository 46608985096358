import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import React from 'react';
import * as Styled from './slpLowerImageWithTextStyle';

const SlpLowerImageWithText = ({ sectionData }) => {
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <Styled.HeaderH1>{children}</Styled.HeaderH1>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParaText>{children}</Styled.ParaText>
      ),
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  return (
    <Styled.BodyContainer>
      <Styled.BodyStyle>
        <Styled.BodyContent>
          <Styled.TextDiv>
            {sectionData?.richText &&
              documentToReactComponents(
                JSON.parse(sectionData?.richText?.raw),
                optionsMainStyle
              )}
          </Styled.TextDiv>
          <Styled.ImageStyle
            src={sectionData?.image?.file?.url}
            alt={sectionData?.image?.file?.fileName}
          ></Styled.ImageStyle>
        </Styled.BodyContent>
      </Styled.BodyStyle>
    </Styled.BodyContainer>
  );
};

export default SlpLowerImageWithText;
